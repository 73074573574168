export const contributors = [
  {
    name: 'Akemi Hong',
    link: 'http://www.studioakemi.com',
    img: 'akemi.jpg',
    affiliation: '(Studio Akemi)',
    title: 'Principal',
    beta: 1,
  },
  {
    name: 'Alexander Hohl',
    link: 'https://medium.com/covidatlas/disease-surveillance-of-covid-19-4e7fcdad03a1?source=false---------0',
    img: 'alexander.jpg',
    affiliation: '(University of Utah)',
    title: 'Assistant Professor, Geography',
    beta: 1,
  },
  {
    name: 'Andr\u00e9s Crucetta',
    link: 'https://spatial.uchicago.edu/directory/andr%C3%A9s-crucetta',
    img: 'andres.jpg',
    affiliation: '(CSDS)',
    title: 'Research Assistant',
    v1: 1,
    v2: 1,
  },
  {
    name: 'Ari Israel',
    link: 'https://www.linkedin.com/in/ariisrael/',
    img: 'ari.jpg',
    title: 'UX Engineer',
    beta: 1,
  },
  {
    name: 'Bibind Vasu',
    link: 'https://www.linkedin.com/in/bibindvasu/',
    img: 'bibind.jpg',
    affiliation: '(CSDS)',
    title: 'Research Assistant',
  },
  {
    name: 'Bin Yu, PhD',
    link: 'https://www.stat.berkeley.edu/~binyu/Site/Welcome.html',
    img: 'bin.jpg',
    affiliation: '(Berkeley)',
    title:
      "Chancellor's Distinguished Professor, Principal of Yu Research Group, Departments of Statistics and EECS",
    beta: 1,
  },
  {
    name: 'Brian Yandell, PhD',
    link: 'https://datascience.wisc.edu/covid19',
    img: 'yandell.jpg',
    affiliation: '(UW-Madison)',
    title:
      'Interim Director at the American Family Insurance Data Science Institute, lead of the UW-Madison COVID Data Science Team',
    alpha: 1,
  },
  {
    name: 'Cecilia Smith, MS, PhD',
    link: 'https://www.lib.uchicago.edu/about/directory/staff/cecilia-smith/',
    img: 'cecilia.jpg',
    affiliation: '(UChicago Library)',
    title: 'GIS and Maps Librarian, Univesity of Chicago',
    beta: 1,
  },
  {
    name: 'Dan Snow, MS',
    link: 'https://sno.ws/about/',
    img: 'Dan.jpg',
    affiliation: '(CSDS)',
    title: 'Software Engineer',
    beta: 1,
  },
  {
    name: 'Erin Abbott',
    link: 'https://spatial.uchicago.edu/directories/full/Research-Assistants',
    img: 'erin.jpg',
    affiliation: '(CSDS)',
    title: 'Research Assistant',
    alpha: 1,
  },
  {
    name: 'Fletcher Berryman',
    link: 'https://medium.com/covidatlas/when-pandemics-collide-the-intersection-of-covid-19-and-americas-opioid-crisis-bd6ac3c0b817',
    img: 'fletcher.jpg',
    title: 'Writer',
    beta: 1,
  },
  {
    name: 'German Cadenas',
    link: 'https://ed.lehigh.edu/faculty/directory/german-cadenas',
    img: 'german.jpg',
    affiliation: '(Lehigh University)',
    v3: 1,
  },
  {
    name: 'Greg Wolverton',
    link: 'https://www.linkedin.com/in/greg-wolverton-234057a',
    img: 'greg.jpg',
    affiliation: '(CSI Solutions)',
    title: 'CTO',
    beta: 1,
    v1: 1,
    v2: 1,
  },
  {
    name: 'Gustavo Pellegrini Dias',
    link: 'https://medium.com/covidatlas/unpacking-the-covid-outbreaks-in-the-meatpacking-industry-2c03ffe8264d',
    img: 'gustavo.jpg',
    affiliation: '(CSDS)',
    title: 'SISRM Fellow',
    beta: 1,
  },
  {
    name: 'Jay Bhatt',
    link: 'https://www.linkedin.com/in/dr-jay-bhatt/',
    img: 'jay.jpg',
    v3: 1,
  },
  {
    name: 'Jinfei Zhu',
    link: 'https://spatial.uchicago.edu/directories/full/2021-to-22',
    img: 'jinfei.jpg',
    affiliation: '(CSDS)',
    title: 'Data Engineering Fellow',
    v3: 1,
  },
  {
    name: 'John Steill',
    link: 'https://www.linkedin.com/in/julia-koschinsky-657599b1',
    img: 'johns.jpg',
    affiliation: '(UW-Madison)',
    title: 'Computational Biologist Morgridge Institute of Research',
    alpha: 1,
  },
  {
    name: 'Julia Koschinsky',
    img: 'julia.jpg',
    affiliation: '(CSDS)',
    title:
      'Executive Director of the Center for Spatial Data Science at the University of Chicago.',
    alpha: 1,
    beta: 1,
  },
  {
    name: 'Karina Acosta Ordonez',
    link: 'https://spatial.uchicago.edu/directories/full/all',
    img: 'karina.jpg',
    affiliation: '(CSDS)',
    title: 'PhD Student, Visiting Scholar',
    alpha: 1,
  },
  {
    name: 'Kathy Reims, MD',
    link: 'https://www.linkedin.com/in/kathy-reims-56279217',
    img: 'kreims.jpg',
    affiliation: '(CSI Solutions)',
    title: 'CMO',
    beta: 1,
  },
  {
    name: 'Kenna Camper',
    link: 'https://kenna-camper.wixsite.com/portfolio',
    img: 'kenna.jpg',
    affiliation: '(CSDS)',
    title: 'Research Assistant Communications Specialist',
    v1: 1,
    v2: 1,
  },
  {
    name: 'Kevin Credit',
    link: 'https://spatial.uchicago.edu/directory/kevin-credit-phd',
    img: 'kevincredit.jpg',
    affiliation: '(CSDS)',
    title: 'Fellow',
    beta: 1,
  },
  {
    name: 'Kevin Little, PhD',
    link: 'https://www.iecodesign.com/about-us',
    img: 'kevin.jpg',
    affiliation: '(Informing Ecological Design, LLC)',
    title: 'Principal',
    alpha: 1,
  },
  {
    name: 'Laura Chen',
    link: 'https://spatial.uchicago.edu/directories/full/Research-Assistants',
    img: 'laura.jpg',
    affiliation: '(CSDS)',
    title: 'Research Assistant Communications Specialist',
    v1: 1,
    v2: 1,
  },
  {
    name: 'Lawrence T. Brown, PhD',
    link: 'https://uwphi.pophealth.wisc.edu/staff/brown-lawrence/',
    img: 'lawrence.jpg',
    affiliation: '(CHR&R)',
    title:
      'Director of County Health Rankings & Roadmaps, Visiting Associate Professor in the University of Wisconsin Population Health Institute',
    beta: 1,
    v1: 1,
    v2: 1,
  },
  {
    name: 'Luc Anselin',
    link: 'https://spatial.uchicago.edu/directory/luc-anselin-phd',
    img: 'luc.jpg',
    affiliation: '(CSDS)',
    title:
      'Stein-Freiler Distinguished Service Professor of Sociology at the College, Director of the Center for Spatial Data Science, & a Senior Fellow at NORC.',
    alpha: 1,
  },
  {
    name: 'Marjorie Givens, PhD',
    link: 'https://uwphi.pophealth.wisc.edu/staff/givens-phd-marjory/',
    img: 'givens.jpg',
    affiliation: '(CHR&R)',
    title: 'Associate Director, Population Health Institute',
    beta: 1,
    v3: 1,
  },
  {
    name: 'Mathew Cerf',
    link: 'https://medium.com/covidatlas/corona-diaries-of-the-urban-poor-grassroots-perspectives-at-a-time-of-pandemic-a3d67c94b4ae',
    img: 'mathew.jpg',
    affiliation: '(Justice and Empowerment Initiatives)',
    title: 'Media and Data Strategy Fellow',
    beta: 1,
  },
  {
    name: 'Moksha Menghaney',
    link: 'https://www.linkedin.com/in/mokshamenghaney/',
    img: 'Moksha.jpg',
    affiliation: '(CSDS)',
    title: 'Community Mapping Analyst',
    beta: 1,
  },
  {
    name: 'Nancy Goede',
    link: 'https://medium.com/covidatlas/whats-wrong-with-these-people-f6ac287dbca3',
    img: 'nancy.jpg',
    affiliation: '(Augustana Lutheran Church)',
    title: 'Senior Pastor',
    beta: 1,
  },
  {
    name: 'Robert Martin',
    link: 'https://www.linkedin.com/in/robert-martin-7395b0/',
    img: 'robert.jpg',
    title: 'Software Engineer',
    beta: 1,
  },
  {
    name: 'Roger L. Chaufournier',
    link: 'https://www.linkedin.com/in/roger-chaufournier-0499a22a',
    img: 'roger.jpg',
    affiliation: '(CSI Solutions)',
    title: 'CEO',
    beta: 1,
    v1: 1,
    v2: 1,
  },
  {
    name: 'Ryan Wang',
    link: 'https://spatial.uchicago.edu/directories/full/Research-Assistants',
    img: 'ryan.jpg',
    affiliation: '(CSDS)',
    title: 'Spatial Data Science Fellow Data Analyst',
    beta: 1,
  },
  {
    name: 'Sihan Mao',
    link: 'https://www.linkedin.com/in/sihan-mao/',
    img: 'sihano.jpg',
    affiliation: '(CSDS Alumni)',
    title: 'Senior Data & Policy Analyst, City of Pittsburgh',
    alpha: 1,
  },
  {
    name: 'Stephanie Yang',
    link: 'https://spatial.uchicago.edu/directories/full/Research-Assistants',
    img: 'stephanie.jpg',
    affiliation: '(CSDS)',
    title: 'Spatial Data Science Fellow Data Engineer',
    beta: 1,
    v1: 1,
    v2: 1,
  },
  {
    name: 'Sterling Fearing',
    link: 'https://medium.com/covidatlas/covid-pandemic-in-the-navajo-apache-other-native-american-tribes-d1e0580a8ef0',
    img: 'sterling.jpg',
    affiliation: '(UChicago)',
    title: 'Sociology MA Student',
    beta: 1,
  },
  {
    name: 'Steve Goldstein, PhD',
    link: 'https://biostat.wiscweb.wisc.edu/staff/goldstein-steve/',
    img: 'steveg.jpg',
    affiliation: '(UW-Madison)',
    title: 'Botany, Biostatistics and Medical Informatics',
    alpha: 1,
  },
  {
    name: 'Steven Wangen',
    link: 'https://www.linkedin.com/in/steven-wangen/',
    img: 'steve.jpg',
    affiliation: '(UW-Madison)',
    title:
      'Assistant Scientist at the Wisconsin Institute for Discovery, and Data Scientist at the American Family Insurance Data Science Institute.',
    alpha: 1,
  },
  {
    name: 'Toni Williams',
    link: 'https://burness.com/our-people/toni',
    img: 'toni.jpg',
    affiliation: '(Burness Communications)',
    title: 'Vice President',
    beta: 1,
    v1: 1,
    v2: 1,
  },
  {
    name: 'Vidal Anguino',
    link: 'https://spatial.uchicago.edu/directories/full/Research-Assistants',
    img: 'vidal.jpg',
    affiliation: '(CSDS)',
    title: 'Software Engineer',
    beta: 1,
    v1: 1,
    v2: 1,
  },
];
